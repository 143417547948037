.theme-btn {
  height: 40px;
  padding: 0 20px;
  font-weight: 600;
  border-radius: 5px;
  appearance: none;
  border: none;
  min-width: 85px;
  text-transform: capitalize;

  &:focus,
  &:visited {
    outline: none;
  }

  &.primary-btn {
    background: var(--blue2);
    color: var(--white);
  }

  &.border-btn {
    background: transparent;
    border: 1px solid var(--blue2);
    color: var(--blue2);
    border-radius: 5px;
  }

  &.border-btn-grey {
    background: transparent;
    border: 1px solid var(--grey4);
    color: var(--grey2);
    border-radius: 5px;

    &.fw-400 {
      font-weight: 400;
    }
  }
}

/* btn-group */
.btn-group {
  margin: 0 -8px;

  .theme-btn {
    margin: 0 8px;
  }
}
