@import "styles/base/variables";
@import "styles/base/typography";
@import "styles/base/reset";
@import "styles/base/utility";
@import "styles/components/buttons";
@import "styles/base/common";
@import "intl-tel-input/build/css/intlTelInput.css";
.ngx-toastr {
  width: 390px !important; // Specify toastr-dialog width for all devices
}

::ng-deep .btn-primary {
  background-color: #065dbc !important;
  border-color: #065dbc !important;
}

::ng-deep .btn-primary:hover {
  background-color: #0b5ed9 !important;
  border-color: #0b5ed9 !important;
}

.pointer {
  cursor: pointer !important;
}
.iti--separate-dial-code .iti__selected-flag {
  background-color: transparent;
}
.iti__selected-flag {
  padding: 0;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #065dbc;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #065dbc;
}
