:root {
  --grey1: #333333;
  --grey2: #666666;
  --grey3: #999999;
  --grey4: #cccccc;
  --grey5: #dddddd;
  --grey6: #e5e5e5;
  --grey7: #f8f9fa;
  --grey8: #7e7d7d;
  --grey9: #fafafa;
  --blue1: #05a0f8;
  --blue2: #065dbc;
  --blue3: #001aaf;
  --white: #ffffff;
  --red: #f44336;
  --orange1: #fa6900;
  --grey10: #949BA9;
  --blue4: #065DBC;
  --green: #5AFF7E;
  --black2: #344857;
  --blue-light: #DCEDFF;
  --black3: #0E0E0E;
  --blue-light2: #C7D6EE;
  --white2: #F4FAFE;
  --black4: #292C2F;
  --white3: #FBFCFF;
  --l-grey: #F5F7FC;
}
