/* typography */
body {
  font-family: "Poppins", sans-serif !important;
  font-size: 14px !important;
  padding: 0;
  margin: 0;
  font-weight: 400;
  color: var(--grey1) !important;
  overflow: hidden;

  * {
    font-family: "Poppins", sans-serif !important;
  }
}

h1 {
  font-size: 24px !important;
  font-weight: 600 !important;
  color: var(--blue1) !important;
}
.fs-12 {
  font-size: 12px !important;
}
.f-12 {
  font-size: 12px !important;
}

.f-13 {
  font-size: 13px !important;
}

.f-14 {
  font-size: 14px !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}

.f-24 {
  font-size: 24px !important;
}

.f-32 {
  font-size: 32px !important;
}
.f-22 {
  font-size: 22px !important;
}

.f-28 {
  font-size: 28px !important;
}

.f-80 {
  font-size: 80px !important;
}
.f-48 {
  font-size: 48px !important;
}

.s-text {
  font-size: 24px;
}

.subtext {
  font-size: 14px !important;
}

/* font-weight */
.fw-100 {
  font-weight: 100;
}
.fw-200 {
  font-weight: 200;
}
.fw-300 {
  font-weight: 300;
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
