/* text color  */
.text-blue {
  color: var(--blue1);
}
.text-dark-blue {
  color: var(--blue2)!important;
}
.text-darkgrey {
  color: var(--grey1);
}
.text-grey {
  color: var(--grey2);
}
.text-orange {
  color: var(--orange1);
}
.error {
  color: var(--red);
}
.white {
  color: var(--white);
}
.text-blue {
  color: var(--blue2);
}
.f-black {
  color: var(--black3);
}
.f-black2 {
  color: var(--black2);
}
.light-white {
  color: var(--white2);
}

/*  bg */
.bg-grey {
  background: var(--grey9);
}
.black-bg {
  background: var(--black4);
}
.white-bg {
  background: var(--white);
}
.white-bg3 {
  background: var(--white3);
}

/* width */
.auto-width {
  width: auto !important;
}

.width-auto {
  width: auto;
}
.w50 {
  width: 50px;
}
.w80 {
  width: 80px;
}

.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-47 {
  width: 47%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-100 {
  width: 100%;
}
.w-130px {
  min-width: 130px;
  width: 130px;
}
.max-width-130 {
  max-width: 130px;
}

@media screen and (max-width: 767px) {
  .sm-width-auto {
    width: auto !important;
  }
  .sm-block {
    display: block;
  }
}

.display-grid {
  display: grid;
}

/* text align */
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

@media screen and (max-width: 767px) {
  .sm-text-left {
    text-align: left !important;
  }
  .sm-text-right {
    text-align: right !important;
  }
  .sm-text-center {
    text-align: center !important;
  }
  .sm-column-reverse {
    flex-direction: column-reverse;
  }
  .sm-column-reverse {
    flex-direction: column-reverse;
  }
  .sm-justify-start {
    justify-content: flex-start !important;
  }
  .justify-content-end.sm-justify-start {
    justify-content: flex-start !important;
  }
}

@media screen and (max-width: 991px) {
  .md-justify-start {
    justify-content: flex-start !important;
  }
  .justify-content-end.md-justify-start {
    justify-content: flex-start !important;
  }
  .md-mt-15 {
    margin-top: 15px !important;
  }
  .md-mt-20 {
    margin-top: 20px !important;
  }
  .addtopPd {
    padding-top: 15px !important;
  }
}

/* text-decoration */
.text-decoration-no {
  text-decoration: none;
}
.text-decoration-underline {
  text-decoration: underline;
}

/* padding */
.pt-0 {
  padding-top: 0;
}
.pt-05 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-100 {
  padding-top: 100px;
}
.pb-0 {
  padding-bottom: 0;
}
.pb-10 {
  padding-bottom: 30px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-250 {
  padding-bottom: 250px;
}
.pb-310 {
  padding-bottom: 310px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-0 {
  padding-left: 0;
}
.pd-30 {
  padding: 30px !important;
}
.pl-20 {
  padding-left: 20px;
}

@media screen and (max-width: 767px) {
  .sm-pt-10 {
    padding-top: 10px;
  }
  .sm-pt-20 {
    padding-top: 20px;
  }
  .sm-pt-30 {
    padding-top: 30px;
  }
  .sm-pt-40 {
    padding-top: 40px;
  }
  .sm-pt-50 {
    padding-top: 50px;
  }
  .sm-pt-60 {
    padding-top: 60px;
  }
  .sm-pt-70 {
    padding-top: 70px;
  }
  .sm-pt-80 {
    padding-top: 80px;
  }
  .sm-pt-90 {
    padding-top: 90px;
  }
  .sm-pt-100 {
    padding-top: 100px;
  }
  .sm-pb-10 {
    padding-bottom: 10px;
  }
  .sm-pb-10 {
    padding-bottom: 20px;
  }
  .sm-pb-30 {
    padding-bottom: 30px;
  }
  .sm-pb-40 {
    padding-bottom: 40px;
  }
  .sm-pb-50 {
    padding-bottom: 50px;
  }
  .sm-pb-60 {
    padding-bottom: 60px;
  }
  .sm-pb-70 {
    padding-bottom: 70px;
  }
  .sm-pb-80 {
    padding-bottom: 80px;
  }
  .sm-pb-90 {
    padding-bottom: 90px;
  }
  .sm-pb-100 {
    padding-bottom: 100px;
  }
  .sm-pl-0 {
    padding-left: 0 !important;
  }
  .sm-pr-0 {
    padding-right: 0 !important;
  }
}

/* margin */
.no-margin {
  margin: 0 !important;
}

.m20 {
  margin: 20px;
}

.mt-0 {
  margin-top: 5px;
}
.mt-05 {
  margin-top: 5px !important;
}

.mlr-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.mtb-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.mt-0 {
  margin-bottom: 0 !important;
}
.mt-10 {
  margin-top: 10px !important;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-25 {
  margin-top: 25px;
}
.mt-26 {
  margin-top: 26px;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-35 {
  margin-top: 35px !important;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-100 {
  margin-top: 100px;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.mb-05 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-100 {
  margin-bottom: 100px;
}
.ml-0 {
  margin-left: 0 !important;
}
.ml-10 {
  margin-left: 10px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-20 {
  margin-left: 20px;
}
.ml-25 {
  margin-left: 25px;
}
.ml-30 {
  margin-left: 30px;
}
.mr-0 {
  margin-right: 0 !important;
}
.mr-10 {
  margin-right: 10px;
}
.mr-15 {
  margin-right: 15px;
}
.mr-20 {
  margin-right: 20px !important;
}
.mr-25 {
  margin-right: 25px;
}
.mb-250 {
  margin-bottom: 250px !important;
}
@media screen and (max-width: 767px) {
  .sm-mt-0 {
    margin-top: 0;
  }
  .sm-mt-10 {
    margin-top: 10px !important;
  }
  .sm-mt-15 {
    margin-top: 15px;
  }
  .sm-mt-20 {
    margin-top: 20px !important;
  }
  .sm-mt-30 {
    margin-top: 30px;
  }
  .sm-mt-40 {
    margin-top: 40px;
  }
  .sm-mt-50 {
    margin-top: 50px;
  }
  .sm-mt-60 {
    margin-top: 60px;
  }
  .sm-mt-70 {
    margin-top: 70px;
  }
  .sm-mt-80 {
    margin-top: 80px;
  }
  .sm-mt-90 {
    margin-top: 90px;
  }
  .sm-mt-100 {
    margin-top: 100px;
  }
  .sm-mb-0 {
    margin-bottom: 0;
  }
  .sm-mb-10 {
    margin-bottom: 10px;
  }
  .sm-mb-15 {
    margin-bottom: 15px;
  }
  .sm-mb-20 {
    margin-bottom: 20px !important;
  }
  .sm-mb-30 {
    margin-bottom: 30px;
  }
  .sm-mb-40 {
    margin-bottom: 40px;
  }
  .sm-mb-50 {
    margin-bottom: 50px;
  }
  .sm-mb-60 {
    margin-bottom: 60px;
  }
  .sm-mb-70 {
    margin-bottom: 70px;
  }
  .sm-mb-80 {
    margin-bottom: 80px;
  }
  .sm-mb-90 {
    margin-bottom: 90px;
  }
  .sm-mb-100 {
    margin-bottom: 100px;
  }
}

/* position */
.position-relative {
  position: relative;
}

/* border-top */
.border-top {
  border-top: 1px solid var(--grey4) !important;
}
.border-bottom {
  border-bottom: 1px solid var(--grey4) !important;
}
.border-left {
  border-left: 1px solid var(--grey4) !important;
}
.border-right {
  border-right: 1px solid var(--grey4) !important;
}

.no-border {
  border: none !important;
}


/* resposive class */
@media screen and (max-width: 991px) {
  .md-display-block {
    display: block !important;
  }
  .px-0 {
    &.md-px-0 {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .sm-display-block {
    display: block !important;
  }
  .px-0 {
    &.sm-px-0 {
      padding-right: 10px !important;
      padding-left: 10px !important;
    }
  }
}
.cPointer{
  cursor: pointer!important;
}
